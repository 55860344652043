import { SanityLogoGrid } from '@data/sanity/queries/types/modules'

type LogoGridProps = SanityLogoGrid

const LogoGrid = ({ title, logos }: LogoGridProps) => (
  <section className="py-8">
    <div className="container">
      {title && (
        <h4 className="text-gray text-base mb-10 text-center">{title}</h4>
      )}

      <div className="flex gap-8 md:gap-x-16 xl:gap-x-20 flex-wrap justify-center items-center">
        {logos?.map(({ url, alt }, index) => {
          return (
            <picture
              className="max-w-[11rem]"
              key={`logo-grid-item-${index}-${alt
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}}`}
            >
              <img src={url} alt={alt} />
            </picture>
          )
        })}
      </div>
    </div>
  </section>
)

export default LogoGrid
